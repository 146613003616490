import React from "react";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import HttpError from "@packages/common/src/components/error-handlers/HttpError";
import useUserContext from "../../../../context/get-user/useUserContext";

const UserInfo = () => {
  const { user, responseError } = useUserContext();

  return responseError !== null ? (
    <HttpError axiosError={responseError} />
  ) : (
    <>
      <ListItemIcon>
        <AccountCircleIcon fontSize="large" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="subtitle1">
          {!!user?.username ? user?.username : ""}
        </Typography>
        <Typography variant="body1">
          {!!user?.email ? user?.email : ""}
        </Typography>
      </ListItemText>
    </>
  );
};
export default UserInfo;
