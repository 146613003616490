import React from "react";

import DropdownMenuItem from "./DropdownMenuItem";
import DropdownMenu from "./DropdownMenu";

const DropdownChildren: React.FC<CommonHeader.IMenuProps> = ({
  anchorEl,
  handleClose,
  dropdownData,
  ...props
}) => {
  return (
    <DropdownMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      sx={{ "& a": { textDecoration: "none" } }}
      {...props}
    >
      {dropdownData &&
        dropdownData.map(({ id, ...props }) => (
          <DropdownMenuItem key={id} handleClose={handleClose} {...props} />
        ))}
    </DropdownMenu>
  );
};

export default DropdownChildren;
