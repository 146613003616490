import React from "react";
import Link from "next/link";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const MyListItemHref: React.FC<CommonHeader.IMyListItemProps> = ({
  route,
  className,
  label,
  toggleDrawer,
  target = "_self",
}) => (
  <Link href={route}>
    <a target={target}>
      <ListItem
        sx={className ? { ml: 4 } : undefined}
        onClick={toggleDrawer(false)}
      >
        <ListItemText>{label}</ListItemText>
      </ListItem>
    </a>
  </Link>
);

export default MyListItemHref;
