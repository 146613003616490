import React from "react";
import Link from "next/link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const AuthActions = () => {
  return (
    <Box
      display="flex"
      columnGap={2}
      sx={{ "& a": { textDecoration: "none" } }}
    >
      <Link href="/accounts/login">
        <a>
          <Button variant="outlined">Login</Button>
        </a>
      </Link>
      <Link href="/accounts/signup">
        <a>
          <Button variant="contained" color="primary">
            Join For Free
          </Button>
        </a>
      </Link>
    </Box>
  );
};

export default AuthActions;
