import React from "react";
import Link from "next/link";
import Button from "@mui/material/Button";

const UpLgNavItem: React.FC<CommonHeader.IUpLgNavItemProps> = ({
  route,
  target,
  label,
  buttonProps,
}) => (
  <Link href={route}>
    <a target={target}>
      <Button variant="text" color="primary" {...buttonProps}>
        {label}
      </Button>
    </a>
  </Link>
);

export default UpLgNavItem;
