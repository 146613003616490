import React, { useState, Fragment } from "react";
import Link from "next/link";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material";

const NavbarListItem: React.FC<{
  label: string;
  data: CommonHeader.IDropdownDataProps[];
  onClick: React.MouseEventHandler<HTMLLIElement>;
}> = ({ label, data, onClick }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box
      sx={{
        "& .MuiListItem-root": {
          cursor: "pointer",
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        "& .MuiListItem-root:hover": {
          width: "100%",
          backgroundColor: "rgba(68, 146, 76, 0.04)",
        },
      }}
    >
      <ListItem onClick={() => setOpen(!open)}>
        <ListItemText primaryTypographyProps={{ variant: "subtitle1" }}>
          {label}
        </ListItemText>
        {open ? <ExpandLess color="primary" /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            "& .MuiListItem-root": {
              paddingLeft: theme.spacing(8),
            },
            "& a": {
              textDecoration: "none",
            },
          }}
        >
          {data.map(({ id, route, label, target }) => (
            <Fragment key={id}>
              <Link href={route}>
                <a target={target}>
                  <ListItem onClick={onClick}>
                    <ListItemText>{label}</ListItemText>
                  </ListItem>
                </a>
              </Link>
            </Fragment>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};

export default NavbarListItem;
