import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import UserInfo from "./side-bar/UserInfo";
import UserNotAuthenticated from "./side-bar/UserNotAuthenticated";
import MyListItemHref from "./MyListItemHref";
import Logout from "./Logout";
import {
  forIndividuals,
  forIndustry,
  sideBarAppRoutes,
  sidebarNavActions,
} from "./headerData";
import {
  FRONTEND_DASHBOARD_URL,
  FRONTEND_PROFILE_EDIT_URL,
} from "../../../utils/constants";
import authService from "../../../service/authService";
import NavbarListItem from "./dropdowns/common/NavbarListItem";

const SideBar: React.FC<CommonHeader.ISideBarProps> = ({
  drawer,
  toggleDrawer,
}) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <List sx={{ width: 270 }}>
        <ListItem divider sx={{ mb: 4 }}>
          {authService.isAuthenticated ? (
            <UserInfo />
          ) : (
            <UserNotAuthenticated />
          )}
        </ListItem>
        <Box
          sx={{
            "& .MuiListItem-root": {
              cursor: "pointer",
              py: 1,
            },
            "& .MuiListItem-root:hover": {
              width: "100%",
              backgroundColor: "rgba(68, 146, 76, 0.04)",
            },
            "& a": {
              textDecoration: "none",
            },
          }}
        >
          <MyListItemHref
            toggleDrawer={toggleDrawer}
            label="AI Models"
            route="https://app.aimarketplace.co/models"
            target="_blank"
          />
          <NavbarListItem
            label="Industry"
            data={forIndustry}
            onClick={toggleDrawer(false)}
          />
          <NavbarListItem
            label="Community"
            data={forIndividuals}
            onClick={toggleDrawer(false)}
          />
          {sideBarAppRoutes.map(({ id, target, ...props }) => (
            <MyListItemHref
              key={id}
              toggleDrawer={toggleDrawer}
              target={target}
              {...props}
            />
          ))}
          <Divider variant="fullWidth" sx={{ my: 4 }} />
          {authService.isAuthenticated ? (
            <>
              <MyListItemHref
                route={FRONTEND_PROFILE_EDIT_URL}
                label="Profile"
                toggleDrawer={toggleDrawer}
              />
              <MyListItemHref
                route={FRONTEND_DASHBOARD_URL}
                label="Dashboard"
                toggleDrawer={toggleDrawer}
              />
              <Logout />
            </>
          ) : (
            <>
              {sidebarNavActions.map(({ id, ...props }) => (
                <MyListItemHref
                  key={id}
                  toggleDrawer={toggleDrawer}
                  {...props}
                />
              ))}
            </>
          )}
        </Box>
      </List>
    </SwipeableDrawer>
  );
};

export default SideBar;
