import React from "react";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";

import { FRONTEND_LOGIN_URL } from "../../../../utils/constants";

const UserNotAuthenticated = () => {
  return (
    <ListItemText>
      Please
      <MuiLink href={FRONTEND_LOGIN_URL} underline="none">
        <Typography component="span" color="primary">
          &nbsp;login&nbsp;
        </Typography>
      </MuiLink>
      to start your data science learning journey.
    </ListItemText>
  );
};

export default UserNotAuthenticated;
