import React from "react";
import Link from "next/link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { useMediaQuery } from "@mui/material";

import theme from "../../../config/theme";
import Logo from "../common/Logo";
import UpLgNavList from "./UpLgNavList";
import DownLgNavList from "./DownLgNavList";
import { FRONTEND_COMMUNITY_URL } from "../../../utils/constants";

const Header = () => {
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{
        boxShadow: "0px 1.5px 3px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Toolbar>
        <Container maxWidth="xl">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ "& a": { textDecoration: "none" } }}>
              <Link href={FRONTEND_COMMUNITY_URL}>
                <a>
                  <Logo />
                </a>
              </Link>
            </Box>
            {isUpLg ? <UpLgNavList /> : <DownLgNavList />}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
