import React from "react";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";

import UpLgNavItem from "./nav-items/UpLgNavItem";
import { FRONTEND_ABOUT_US_URL } from "../../../utils/constants";
import { forIndividuals, forIndustry } from "./headerData";
import DropdownNavItem from "./nav-items/DropdownNavItem";
import DropdownChildren from "./dropdowns/common/DropdownChildren";
import authService from "../../../service/authService";
import AuthActions from "./AuthActions";
import Profile from "./dropdowns/Profile";

const UpLgNavList = () => {
  return (
    <>
      <Box display="flex" sx={{ "& a": { textDecoration: "none" } }}>
        <UpLgNavItem
          label="AI Models"
          route="https://app.aimarketplace.co/models"
          target="_blank"
        />
        <DropdownNavItem
          label="Industry"
          NavItemComponent={DropdownChildren}
          dropdownData={forIndustry}
        />
        <DropdownNavItem
          label="Community"
          NavItemComponent={DropdownChildren}
          dropdownData={forIndividuals}
        />
        {/* <UpLgNavItem label="AI Planet For Teams" route="/teams" /> */}
        <UpLgNavItem label="About Us" route={FRONTEND_ABOUT_US_URL} />
      </Box>
      <Box>
        {authService.isAuthenticated && (
          <DropdownNavItem label={<PersonIcon />} NavItemComponent={Profile} />
        )}
        {!authService.isAuthenticated && <AuthActions />}
      </Box>
    </>
  );
};

export default UpLgNavList;
