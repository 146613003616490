import React from "react";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import useOnHoverDropdown from "../../../../custom-hooks/useOnHoverDropdown";

const DropdownNavItem: React.FC<CommonHeader.IDropdownNavItemProps> = ({
  label,
  NavItemComponent,
  isLink,
  route,
  dropdownData,
  expandIconColor,
}) => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    closeDropdownWithDelay,
    handleDropdownMouseEnter,
  } = useOnHoverDropdown();

  return (
    <>
      <Button
        onMouseEnter={handleClick}
        onMouseLeave={closeDropdownWithDelay}
        onClick={handleClick}
        variant="text"
        color="primary"
        endIcon={
          anchorEl !== null ? (
            <ArrowDropUpIcon htmlColor={expandIconColor} />
          ) : (
            <ArrowDropDownIcon htmlColor={expandIconColor} />
          )
        }
      >
        {isLink ? (
          <MuiLink href={route} underline="none" style={{ color: "#44924C" }}>
            {label}
          </MuiLink>
        ) : (
          label
        )}
      </Button>
      <NavItemComponent
        anchorEl={anchorEl}
        handleClose={handleClose}
        MenuListProps={{
          onMouseEnter: handleDropdownMouseEnter,
          onMouseLeave: handleClose,
        }}
        dropdownData={dropdownData}
      />
    </>
  );
};

export default DropdownNavItem;
